// /abcd_react_abcd
const appConfig = {
  rootPath: '',
  company: {
    name: "CÔNG TY TNHH LẬP TRÌNH VÀ QUẢNG CÁO GAME",
    address:
      "Tổ 8, ấp Thanh Bình 3, Xã Bình Châu, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu, Việt Nam",
    phoneNumber: "0933333325",
    email: "infor@laptrinhvaquangcaogame.com",
    website: "https://laptrinhvaquangcaogame.com/",
    businessCode: "3502509452",
  },
};

export default appConfig;
